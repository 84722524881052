<template>
    <div class="list-group-item flex-column align-items-start" :class="listItemClass[workObject.work_status]"
        @click="$emit('click:item', workObject)">
        <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">
                Kreiran: {{ formatDate(workObject.created_at) }}
            </h5>
            <small>{{ status[workObject.work_status] }}</small>
        </div>
        <div class="location">
            <span class="me-2">
                Poslednja izmena: {{ formatDate(workObject.updated_at) }}
            </span>
        </div>
        <div>
            <h6 v-if="workObject.finished_units">Uradjeno jedinica {{ workObject.finished_units }}</h6>
            <p>{{ workObject.review_comment }}</p>
        </div>
        <div>
            <h6 class="mt-3 mb-0">Radinici: <span v-for="employees in workObject.job_assignment_obj.employees" :key="employees.id"> {{ employees.first_name }} {{ employees.last_name }}, </span></h6>
            <h6>Menadzer: <span> {{ workObject.job_assignment_obj.manager.first_name }} {{ workObject.job_assignment_obj.manager.last_name }}, </span></h6>
        </div>

    </div>
</template>

<script>

import UtilService from '@/service/UtilService.js';

export default {

    emits: ['click:item'],

    props: {
        workObject: Object
    },

    data() {
        return {
            status: {
                ASSIGNED: "DODELJEN",
                IN_PROGRESS: "U TOKU",
                SUBMITTED: "ZAVRŠENO (Čeka pregled)",
                REVIEWED: "PREGLEDANO",
                NOT_STARTED: "NIJE POČETO"
            },
            listItemClass: {
                ASSIGNED: "list-group-item-light",
                IN_PROGRESS: "list-group-item-primary",
                SUBMITTED: "list-group-item-warning",
                REVIEWED: "list-group-item-success",
                NOT_STARTED: "list-group-item-secondary"
            }
        }
    },

    methods: {
        getLocationFullindex: UtilService.getLocationFullindex,
        getPositionFullindex: UtilService.getPositionFullindex,
        fullNameList: UtilService.fullNameList,
        formatDate: UtilService.formatDate,
    }
}

</script>

<style scoped>
.location {
    text-align: initial;
}
</style>