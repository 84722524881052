<template>
    <div>
        <div class="history-list" v-if="task">
            <p class="mb-0">Jedinica: <strong>{{task.work_position_obj.position_unit_obj.norm_quantity}} {{task.work_position_obj.position_unit_obj.norm_unit}}</strong></p>
            <p class="mb-0">Vreme po jedinici: <strong>{{task.work_position_obj.position_unit_obj.minutes_per_unit}} min</strong></p>
            <p class="mb-3" v-if="task.normative">Norma: <strong>{{task.normative}} {{ task.norm_unit }}</strong></p>
            <p class="mb-3" v-else>Norma: <strong>Nije normirano</strong></p>
            <span>{{ task.task_status }}</span>
            <hr>
        
            <div class="d-grid gap-2 mb-3">
                <template v-if="user.role === 'MANAGER' || user.role === 'ADMIN'">
                    <form v-if="task.task_status === 'CREATED' || task.task_status === 'NOT_READY'" v-on:submit.prevent="setReady">
                        <div class="row">
                            <div class="col-md-7">
                                <label for="inputDate" class="form-label">Datum</label>
                                <input type="date" class="form-control" id="inputDate" v-model="readyForm.date" :min="formatDatePattern(addDate(getMoment(), '-3', 'days'))" :max="formatDatePattern(getMoment())">
                            </div>
                            <div class="col-md-5">
                                <label for="inputTime" class="form-label">Vreme ({{readyForm.time}})</label>
                                <input type="time" class="form-control" id="inputTime" v-model="readyForm.time" value="07:00" :max="maxTime" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button type="submit" class="btn btn-lg btn-warning w-100 mt-2"><i class="fas fa-user-check"></i> Spremno za rad</button>
                            </div>
                        </div>
                    </form>
    
                    <router-link v-else-if="task.task_status === 'READY'" type="button" class="btn btn-primary" tag="button"
                        :to="{ name: 'JobAssignment', query:{'workOrderId': task.work_location_obj.work_order_id, 'workLocationId': task.work_location_id, 'workPositionId': task.work_position_id}}">
                        <i class="fas fa-hard-hat"></i> Uputi radnike
                    </router-link>
                </template>
                <template v-else-if="user.role === 'WORKER'">
                    <!-- <router-link v-if="task" type="button" class="btn btn-primary" tag="button" :disabled="getWorksStage() == 2"
                        :to="{ name: 'JobAssignment', query:{'workOrderId': workOrderID, 'workLocationId': selectedWorkLocation.id, 'workPositionId': selectedWorkPosition.id, 'getPreWorkOrdersForEmployee': user.id}}">
                        <i class="fas fa-hard-hat"></i> Započni posao
                    </router-link> -->
                </template>
    
            </div>
    

            <div class="list-group gap">
                <!-- <h5 v-for="worker in task.assignment.employees" :key="worker.id">{{ worker.first_name }} {{ worker.last_name }}</h5> -->
                <!-- <h5>Menadzer: {{ assignment.manager.first_name }} {{ assignment.manager.last_name }}</h5> -->
                <task-works-item v-for="(wo, index) in task.works_obj" :key="index" :workObject="wo" />
            </div>

                    <!-- <a :key="work.id" class="list-group-item list-group-item-action" :class="getClassForCardItem(work)">
                        <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                                <span class="badge bg-dark">{{workLabel(work)}}</span>
                            </h5>
                            <small class="">Pre {{getWorkIntervalStamp(work)}}</small>
                        
                        </div>
                        <div class="row">
                            <div class="col-6" v-if="work.job_assignment.employees.length">
                                <h6 class="mb-0">Radnici</h6>
                                <ul>
                                    <li v-for="employee in work.job_assignment.employees" :key="employee.id">
                                        <router-link data-bs-dismiss="modal" :to="{ name: 'EmployeesCardsPage'}">{{userFullName(employee)}}</router-link>
                                    </li>    
                                </ul>
                            </div>
    
                            <div class="col-6">
                                <h6 class="mb-0">Menadžer</h6>
                                <ul>
                                    <li>
                                        <router-link data-bs-dismiss="modal" :to="{ name: 'EmployeesCardsPage'}">{{userFullName(work.job_assignment.manager)}}</router-link>
                                    </li>
                                </ul>
                            </div>
    
                        </div>
                        <p class="mb-0">{{work.work_status=='READY'?'Datum provere':'Početo'}}: <span class="badge bg-dark">{{formatDate(work.job_assignment.started_date_time)}}</span> <span class="badge bg-dark">{{formatTime(work.job_assignment.started_date_time)}}</span></p>
                        <p class="mb-0" v-if="work.job_assignment.finished_date_time">Završeno: <span class="badge bg-dark">{{formatDate(work.job_assignment.finished_date_time)}}</span> <span class="badge bg-dark">{{formatTime(work.job_assignment.finished_date_time)}}</span></p> 
                        <p class="mb-0" v-if="work.job_assignment.reviewed_date_time">Ocenjeno: <span class="badge bg-dark">{{formatDate(work.job_assignment.reviewed_date_time)}}</span> <span class="badge bg-dark">{{formatTime(work.job_assignment.reviewed_date_time)}}</span></p> 
        -->
    
                        <!-- <small class="text-light-muted">{{getWorkConsumedTime(work)}} | Predviđeno: {{aproxNormative()}} min</small> -->
                    <!-- </a> -->
                <!-- </div> -->

        <!-- </div> --> 
        </div>
        <div v-else>
            Loading
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import UtilService from '@/service/UtilService.js';
import JobAssignmentService from '@/service/JobAssignmentService.js';
import TaskWorksItem from './worker-jobs/TaskWorksItem.vue';

export default {
    components: {TaskWorksItem},

    props: {
        task: {
            type: Object
        }
    },

    data () {
        return {
            readyForm: {
                date: UtilService.formatDatePattern(UtilService.getMoment()),
                time: UtilService.formatDatePattern(UtilService.getMoment(), "HH:mm"),
            }
        }
    },  

    computed: {
        ...mapState([
        'user',
        ]),
        
        workOrderID() {
        return parseInt(this.$route.params.woId)
        },

        maxTime() {
            if (this.readyForm.date == UtilService.formatDatePattern(UtilService.getMoment())) return UtilService.formatDatePattern(UtilService.getMoment(), "HH:mm");
            return "";
        }
    },
    methods: {
        
        workLabel : UtilService.workLabel,
        workStatusClass : UtilService.workStatusClass,
        formatDate : UtilService.formatDate,
        formatTime : UtilService.formatTime,
        userFullName : UtilService.userFullName,
        intervalToArray : UtilService.intervalToArray,
        intervalArrayFullString : UtilService.intervalArrayFullString,
        intervalArrayShortString : UtilService.intervalArrayShortString,
        formatDatePattern: UtilService.formatDatePattern,
        formatDateTime: UtilService.formatDateTime,
        getMoment: UtilService.getMoment,
        addDate: UtilService.addDate,

        aproxNormative() {
            return this.task.normaative * this.task.work_position_obj.position_unit.minutes_per_unit;
        },


        getWorkConsumedTime(work) {
            return this.intervalArrayFullString(this.intervalToArray(work.job_assignment.started_date_time, work.job_assignment.finished_date_time));
        },
        
        getWorkIntervalStamp(work) {
            return this.intervalArrayShortString(this.intervalToArray(work.job_assignment.started_date_time));
        },

        getClassForCardItem(work) {            
            if (work.work_status == 'REVIEWED') return 'work-bg-approved';
            if (work.work_status == 'SUBMITTED') return 'work-bg-checked';
            if (work.work_status == 'IN_PROGRESS') return 'work-bg-not-started';
            if (work.work_status == 'ASSIGNED') return 'work-bg-info';
            if (work.work_status == 'NOT_STARTED') return 'work-bg-warning';
            
        },



        onSetReady(task) {
            let newTask = UtilService.deepClone(this.task)
            newTask = Object.assign(newTask, task)

            this.$emit('onUpdate', newTask);
        },


        async setReady() {
            let data = {
                url: {
                    workOrderId: this.task.work_position_obj.work_order_id,
                    taskId: this.task.id,
                },
                data: {
                    datetime: this.readyForm.date + "T" + this.readyForm.time,
                }
            };

            try {
                let response = await JobAssignmentService.setworkOrderTaskReady(data)
                console.log(response);
                this.onSetReady(response.data)
            } catch (error) {
                alert("Ne moguce promeniti status u Spremno")
                console.log(error)
            }
        },

        getTypeName() {
            if (this.selectedWorkLocation.type) return this.selectedWorkLocation.type.name
            return "Nije normirano"
        }

    }
        
}
</script>

<style scoped>
    .list-group-item a {
        font-weight: bold;
    }

    /* a{text-decoration: none} */
   
   .list-group-item.bg-danger a {
        color:rgb(43, 152, 255);
    }

    .text-light-muted {
        color: #eeeeee;
    }

    .work-cards .work-bg-approved {
        background: #178129;
        color: #ffffff;
    }

    .work-cards .work-bg-approved a {
        color: #d5d9ff;
    }

    .work-cards .work-bg-rejected {
        background: #ff5656;
        color: #ffffff;
    }
    .work-cards .work-bg-rejected a {
        color: #d5d9ff;
    }
    .work-cards .work-bg-warning {
        background: #ffff50;
    }

</style>