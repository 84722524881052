<template>
    <div class="groups-body">
    <div class="alert alert-warning dependency-warning" role="alert">
    Izmena poslova u grupi utiče na lokacije:
    <ul class="">
        <li><span class="badge bg-secondary bg-small">1</span> Ulaz</li>
        <ul>
            <li><span class="badge bg-secondary bg-small">1.2</span> Sprat 1</li>
            <ul>
                <li><span class="badge bg-info bg-small">1.2.1</span> Stan 1</li>
                <li><span class="badge bg-info bg-small">1.2.2</span> Stan 2</li>
                <li><span class="badge bg-info bg-small">1.2.3</span> Stan 3</li>
                <li><span class="badge bg-info bg-small">1.2.4</span> Stan 4</li>
                <li><span class="badge bg-info bg-small">1.2.5</span> Stan 5</li>
                <li><span class="badge bg-info bg-small">1.2.6</span> Stan 6</li>
                <li><span class="badge bg-info bg-small">1.2.7</span> Stan 7</li>
            </ul>
        </ul>
    </ul>
    </div>
        <form>
            <VueNestable
                v-model="nestableItems"
                class-prop="class"
                @input="nestableChanged"
                >

                <template slot-scope="{ item }">
                <!-- Regular view -->        
                <div class="clearfix" for="1.1">
                            
                    <div v-if="item.workable" class="form-check">

                        <input class="form-check-input" type="checkbox" value="" v-model="item.includedPosition" :id="item.fullIndex">
                        <label class="form-check-label d-block item-text" :for="item.fullIndex">
                            {{item.fullIndex}} {{ item.text }}
                        </label>
                    </div>
                    <div v-if="!item.workable" class="item-text">
                        <span>{{item.fullIndex}} {{ item.text }}</span>
                    </div>
                </div>

                </template>


            </VueNestable>

        </form>
    </div>
</template>

<script>
export default {
  data () {
    return {
      nestableItems: [
        {
          id: 0,
          includedPosition: true,
          workable: false,
          status: 'APPROVED',
          text: 'Pracenje konstrukcije',
          children: [{
            id: 1,
            includedPosition: true,
            workable: true,
            status: 'APPROVED',
            text: 'Trake',
          },{
            id: 2,
          includedPosition: true,
          workable: true,
            status: 'REJECTED',
            text: 'Creva',
          },{
            id: 3,
            includedPosition: true,
            workable: true,
            status: 'DONE',
            text: 'Otvaranje crevea',
          },{
            id: 4,
            includedPosition: true,
            workable: true,
            status: 'INPROGRESS',
            text: 'kabliranje',
          },
          ],
        },
        {
          id: 5,
            includedPosition: true,
            workable: false,
            status: 'INPROGRESS',
            text: 'Posle zidarskih radova',
          children: [{
            id: 6,
            includedPosition: true,
            workable: true,
            status: 'INPROGRESS',
            text: 'obeležavanje',
          },{
            id: 7,
            includedPosition: false,
            workable: true,
            status: 'NOT_DONE',
            text: 'čeparenje',
          },{
            id: 8,
            includedPosition: true,
            workable: true,
            status: 'NOT_DONE',
            text: 'sečenje',
          },{
            id: 9,
            includedPosition: false,
            workable: true,
            status: 'NOT_DONE',
            text: 'štemanje',
          },
          ],
        }
      ],
    }
  },
  methods: {
    recalculateFullIndex(obj, prefix, depth=0) {
      for(let i = 0 ; i < obj.length ; i++) {
        obj[i].fullIndex = prefix + (i+1);
        obj[i].depth = depth;
        obj[i].class = '';

        if (obj[i].children.length > 0){
          obj[i].class = 'container'
          this.recalculateFullIndex(obj[i].children, prefix+(i+1)+".", depth+1);
        }
      }
    },
    nestableChanged(a) {
      this.recalculateFullIndex(a, "");
    },
  },

  mounted() {
    this.recalculateFullIndex(this.nestableItems, "");
  }




}
</script>

<style scoped>
.dependency-warning {
    min-height: 100px;
    overflow-y: auto;
    height: 50px;
    resize: vertical;
}

.dependency-warning ul {
    list-style-type: none;
    padding-left: 1em;
}


</style>

<style>
.groups-body .nestable-list {
    padding-left: 0 !important;
}

.nestable .form-check .form-check-input{
    margin-top: 0.6em;
}

.nestable .form-check .form-check-label {
    cursor: pointer;
}

</style>