<template>
    <div class="modal fade" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="taskData" class="modal-title" id="exampleModalLabel">
                        <!-- <span class="badge bg-secondary">WorkOrder</span> -->
                        
                        <span>{{taskData.work_location_obj.name}}</span>
                        <!-- <span class="ms-1" v-for="(name, index) in fullNameList(selectedWorkLocation)" :key="index">
                            <span :class="{'badge':true, 'bg-secondary':true }">
                                {{name}}
                            </span>
                            ❯
                        </span> -->

                        <!-- {{selectedWorkLocation.name}} -->
                        <br>
                        <h5>{{taskData.work_position_obj.position_unit_obj.name}}</h5>

                    </h5>
                    <h5 v-else>Loading</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <WorkHistory :task="taskData" v-on="$listeners"/>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import WorkHistory from '@/components/WorkHistory.vue';
    import UtilService from '@/service/UtilService.js';
    import WorkOrderService from '@/service/WorkOrderService.js';
    import { Modal } from 'bootstrap';


    export default {
        components: {
            WorkHistory
        },

        props: {
            taskId: {
                type: String,
                default: null,
            },
            task: {
                type: Object,
                default: null,
            },

            workOrderId: {
                type: Number,
            }
        },

        data() {
            return {
                taskData: null,
            }
        },

        watch: {
            task: {
                handler(val) {
                    if (val) this.updateTask()
                },
                deep: true
            }
        },

        methods: {
            fullNameList : UtilService.fullNameList,

            async loadTask(taskId) {
                let data = {
                    url: {
                        workOrderId: this.workOrderId,
                        taskId: taskId
                    }
                }
                try {
                    let response = await WorkOrderService.getWorkOrderTask(data)
                    let task = response.data
                    this.taskData = task
                } catch (error) {
                    console.log(error)
                }
            },

            async loadWorks(taskId) {
                let data = {
                    url: {
                        workOrderId: this.workOrderId,
                        taskId: taskId
                    },
                    params: {
                        fields: "job_assignment,job_assignment__employees,job_assignment__manager",
                    }
                }
                try {
                    let response = await WorkOrderService.getWorkOrderTaskWorks(data)
                    let works = response.data
                    this.$set(this.taskData, 'works_obj', works)
                    console.log(this.taskData);
                } catch (error) {
                    console.log(error)
                }
            },

            updateTask() {
                console.log("Update task");
                if (this.task === null) {
                    this.loadTask(this.taskId)
                } else {
                    this.taskData = UtilService.deepClone(this.task)
                    if (this.taskData.works_obj == undefined) this.loadWorks(this.task.id)
                }
            },

            //////

            // onModalShown() {
            //     this.updateTask()
            // },

            confirmModal(data) {
                this.hideModal()
                this.$emit('onConfirm', data);
            },

            cancelModal(data) {
                this.hideModal()
                this.$emit('onCancel', data);
            },

            showModal() {
                this.modal.show();
                // this.onModalShown();
            },
            hideModal() {
                this.modal.hide();
            },

            setUpListener() {    
                let myModalEl = this.$el
                this.modal = new Modal(myModalEl, {keyboard: false});
            },

        },

        mounted() {
            this.setUpListener();
        },

    }
    </script>

<style>

</style>